import React from 'react';
import styled, {css} from 'styled-components/macro';
import { ColInSection, Section } from '../layoutComponents';

const Quote = styled.div`
  font-size: 2.5em;
  margin: auto;
  /* font-style: italic; */
  text-align: center;
  color: ${({textColor}) => textColor || 'black'};
  font-family: sacramento;
  ${({textShadow}) => textShadow? css`text-shadow: ${textShadow};`: null}
  @media only screen and (min-width: 1024px) {
    padding: 0px 100px;
    font-size: 4em;
  }
`

const QuoteBlock = ({quote, backgroundColor, textColor, margin, textShadow}) => {

  return (
    //JSX
    <Section background={backgroundColor()} margin={margin}>
      <ColInSection col={1}>
        <Quote textColor={textColor} textShadow={textShadow}>
          {quote}
        </Quote>
      </ColInSection>
    </Section>
  );
}

export default QuoteBlock;