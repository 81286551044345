const attractions = [
  {
    id: 'attr-01',
    name: 'Embassies',
    mapLink: 'https://www.google.com/maps/d/edit?mid=1Vrz1LaTx9tTPFDcRoYelvgx4Bxefr3M7&usp=sharing',
  },
  {
    id: 'attr-02',
    name: 'Hospitals',
    mapLink: 'https://www.google.com/maps/d/edit?mid=1dwanC4BAfygwCxwLvYzjfFKITFv1ehsR&usp=sharing',
  },
  {
    id: 'attr-03',
    name: 'Schools',
    mapLink: 'https://www.google.com/maps/d/edit?mid=1lzF_b3MZ3TW3rh8dRlwCDCg3ZXoajxkq&usp=sharing',
  },
  {
    id: 'attr-04',
    name: 'Gautrain',
    mapLink: 'https://goo.gl/maps/k8PhZtRJfF28hz187',
  },
  {
    id: 'attr-05',
    name: 'Universities',
    mapLink: 'https://goo.gl/maps/CwCRvDSAMbrjRiwj7',
  },
  {
    id: 'attr-06',
    name: 'Gyms',
    mapLink: 'https://goo.gl/maps/ZrWutLajiR8bzpLp6',
  },
  {
    id: 'attr-07',
    name: 'Restaurants',
    mapLink: 'https://goo.gl/maps/xsanztFRGCX7zSo48',
  },
  {
    id: 'attr-08',
    name: 'Parks',
    mapLink: 'https://goo.gl/maps/NcCQx6cYVoPoQhdU8',
  },
  {
    id: 'attr-09',
    name: 'Museums',
    mapLink: 'https://goo.gl/maps/Uo4E9uptW5LnUKcaA',
  },
  {
    id: 'attr-10',
    name: 'Shopping Malls',
    mapLink: 'https://goo.gl/maps/V6ymNn8VtnvCLQyx8',
  },
]

export default attractions