import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const reviews = [
  {
    name: 'Tebogo Nifty',
    review:
      'The garden is fabulous, comfy bed,the staff tops 😁 Expect us very soon 😁😊',
    userImage: (
      <StaticImage
        src='https://lh3.googleusercontent.com/a/AATXAJwvqf9kPhZOAiTp9sZSur5yXRMMcXkxA5Q9wPWu=s80-c0x00000000-cc-rp-mo-br100'
        alt=''
        style={{width: 40, height:40, verticalAlign: 'middle'}}
      />
    ),
  },
  {
    name: 'Denise de Villiers',
    review:
      'Friendly reception, excellent servlce. Beautiful and practical accommodation. Lovely gardens. Excellent security.',
    userImage: (
      <StaticImage
        src='https://lh3.googleusercontent.com/a-/AOh14GharvVladLrZLmerzVaAedvYP8H6aXtd2yPaa6wlS8=s80-c0x00000000-cc-rp-mo-br100'
        alt=''
        style={{width: 40, height:40, verticalAlign: 'middle'}}
      />
    ),
  },
  {
    name: 'AJ Viljoen',
    review:
      'Very Friendly and helpful owner. Beautiful garden and comfortable rooms.',
    userImage: (
      <StaticImage
        src='https://lh3.googleusercontent.com/a-/AOh14Gi3ACVKtIOvLryVwBvoBFEXJMsPx2XFw_ZGvlpC=s80-c0x00000000-cc-rp-mo-ba3-br100'
        alt=''
        style={{width: 40, height:40, verticalAlign: 'middle'}}
      />
    ),
  },
  {
    name: 'Lenah Malema',
    review:
      'The service was awesome I would live there if I could 😂 great place to stay',
    userImage: (
      <StaticImage
        src='https://lh3.googleusercontent.com/a/AATXAJyjyo3PPEELL9DdxOVZvHZJu3ohkLxFeUNBhZk5=s80-c0x00000000-cc-rp-mo-br100'
        alt=''
        style={{width: 40, height:40, verticalAlign: 'middle'}}
      />
    ),
  },
  {
    name: 'William GLD',
    review:
      'A super friendly reception. Very well placed. A beautiful and calm place',
    userImage: (
      <StaticImage
        src='https://lh3.googleusercontent.com/a/AATXAJy4kafHbEJw-6n4tLtvNf-iuB_IPXMe1MK3g8N5=s80-c0x00000000-cc-rp-mo-ba3-br100'
        alt=''
        style={{width: 40, height:40, verticalAlign: 'middle'}}
      />
    ),
  },
  {
    name: 'Francois Louw',
    review:
      'My home away from home when I am in Gauteng. Lovely rooms. Excellent service. Beautiful surroundings.',
    userImage: (
      <StaticImage
        src='https://lh3.googleusercontent.com/a-/AOh14Gh2i8453W4-zRW5sFm-8n_vRbGOWuylPof_DqJJ1Q=s80-c0x00000000-cc-rp-mo-ba3-br100'
        alt=''
        style={{width: 40, height:40, verticalAlign: 'middle'}}
      />
    ),
  },
];

export default reviews;
