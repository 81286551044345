import React from 'react';
import { ColInSection, Section } from '../layoutComponents';
import {
  IoLocationOutline,
  IoPhonePortraitOutline,
  IoMailOutline,
} from 'react-icons/io5';
import { theme } from '../../styles/theme';
import { useTheme } from 'styled-components';

const ContactUs = () => {
  const isInBrowserEnv = () => typeof window !== 'undefined';
  const [width, setWidth] = React.useState(
    isInBrowserEnv() ? window.innerWidth : null
  );
  const { platinum, greenRYB } = useTheme(theme);

  React.useEffect(() => {
    if (isInBrowserEnv()) {
      const handleResizeWindow = () => setWidth(window.innerWidth);
      // subscribe to window resize event "onComponentDidMount"
      window.addEventListener('resize', handleResizeWindow);
      return () => {
        // unsubscribe "onComponentDestroy"
        window.removeEventListener('resize', handleResizeWindow);
      };
    }
  });

  return (
    <>
      <Section
        id='contact'
        alignItems='flex-start'
        background={`linear-gradient(0deg, ${platinum(
          0.5
        )} 0%, ${greenRYB()} 10%)`}
        // backgroundColor={greenRYB()}
        padding='0px'
      >
        <ColInSection col={1} color='white'>
          <h2 style={{ textAlign: 'center' }}>Contact Us</h2>
        </ColInSection>
      </Section>
      <Section justifyContent='center'>
        <ColInSection col={3} style={{ margin: 0 }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <IoLocationOutline />
            &nbsp;26 Nicolson str. Baileys Muckleneuk
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <IoPhonePortraitOutline />
            &nbsp;012 346 2798
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <a href='mailto:touchofclass1@mweb.co.za?subject=Website%20Contact%20Request'>
              <IoMailOutline />
              &nbsp;touchofclass1@mweb.co.za
            </a>
          </div>
        </ColInSection>
        <ColInSection col={3} style={{ margin: 0 }}>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.1783448611063!2d28.21982931517802!3d-25.764672152505383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95619b93129195%3A0xd898b18d45ec05b5!2sTouch%20of%20Class%20Guest%20House!5e0!3m2!1sen!2sza!4v1631456399600!5m2!1sen!2sza'
            width={`${parseInt(width) - 30}`}
            height='450'
            style={{ border: 0, margin: 'auto' }}
            allowfullscreen=''
            loading='lazy'
          ></iframe>
        </ColInSection>
      </Section>
    </>
  );
};

export default ContactUs;

{
  /* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.1783448611063!2d28.21982931517802!3d-25.764672152505383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95619b93129195%3A0xd898b18d45ec05b5!2sTouch%20of%20Class%20Guest%20House!5e0!3m2!1sen!2sza!4v1631456399600!5m2!1sen!2sza" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */
}
