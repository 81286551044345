import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import reviews from '../../data/googleReviews';
import useInterval from '../../hooks/useInterval';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Context } from '../rootElement';

const SlideShowContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Review = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 10px 100px;
  font-size: 1.3em;
  @media only screen and (max-width: 720px) {
    font-size: 0.9em;
    padding: 15px 10px;
  }
  & > div {
    text-shadow: 0px 0px 2px grey;
  }
  & * {
    color: white;
  }
`;

const FadeContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  ${({ show }) =>
    show
      ? css`
          opacity: 1;
        `
      : null}
  transition: opacity 2s linear;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 1) 90%
    );
    /* border-radius: 50%; */
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const GradingCouncilContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.headerHeightBig}px;
  right: 30px;
`;

const HomePageSlideShow = () => {
  const [count, setCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const { featuredImages } = useContext(Context);

  useInterval(() => {
    setCount((count) => count + 1);
  }, 8000);
  useInterval(() => {
    setReviewCount((reviewCount) => reviewCount + 1);
  }, 8000);

  return (
    <SlideShowContainer>
      {featuredImages.map((image, index) => (
        <FadeContainer key={`slide-${index}`}show={count % featuredImages.length === index}>
          <GatsbyImage 
            image={getImage(image.node.gatsbyImageData)} 
            alt='' 
            style={{ width: '100%', height: '100%' }}
          />
        </FadeContainer>
      ))}
      <Review>
        <div style={{ fontStyle: 'italic' }}>
          "{reviews[reviewCount % reviews.length].review}"
        </div>
        <div>
          <StaticImage
            src='../../images/5-stars_copy.png'
            alt='Google Star Rating'
            style={{ width: 100, verticalAlign: 'middle' }}
          />
          <br />
          {reviews[reviewCount % reviews.length].userImage} -{' '}
          {reviews[reviewCount % reviews.length].name} via Google Reviews
        </div>
      </Review>
      <GradingCouncilContainer>
        <StaticImage
          src='../../images/star_grading_copy.png'
          style={{ width: 150 }}
          alt='Tourism Grading Council 4 Stars'
        />
      </GradingCouncilContainer>
    </SlideShowContainer>
  );
};

export default HomePageSlideShow;
