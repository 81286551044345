import React from 'react';
import { ColInSection, Section } from '../../layoutComponents';
import { useTheme } from 'styled-components/macro';
import { theme } from '../../../styles/theme';
import { useStaticQuery } from 'gatsby';
// import Embassies from './embassies';
// import Hospitals from './hospitals';
import attractions from '../../../data/attractions';
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Card from 'react-bootstrap/Card';

const ATTR_IMG_QUERY = graphql`
  query AttrImgQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "attractions" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 300)
        }
      }
    }
  }
`;

const SelfCateringUnits = () => {
  const data = useStaticQuery(ATTR_IMG_QUERY)
  const imgDataArr = data.allFile.nodes;
  const {
    yellowGreenCrayola,
    platinum,
    lightCoral,
    babyPowder,
    davysGrey,
    littleBoyBlue,
    wildBlueYonder,
    greenRYB
  } = useTheme(theme);

  return (
    <>
      <Section
        id='attractions'
        alignItems='flex-start'
        background={`linear-gradient(0deg, ${platinum(0.5)} 0%, ${davysGrey()} 10%)`}
        // backgroundColor={greenRYB()}
        padding='0px'
      >
        <ColInSection col={1} color='white'>
          <h2 style={{ textAlign: 'center' }}>Attractions Nearby</h2>
        </ColInSection>
      </Section>
      <Section backgroundColor={platinum(0.5)}>
        <ColInSection col={1} display='flex' flexFlow='row wrap' justifyContent='center'>
        {attractions.map((attraction, index) => {
        return (
          <Card
            key={`${index}-${attraction.name}`}
            style={{
              width: '18rem',
              boxShadow: '0px 0px 15px -6px #000000',
              borderRadius: 5,
              overflow: 'hidden',
              flexShrink: 0,
              margin: 10,
            }}
          >
          <a href={attraction.mapLink}>
            <GatsbyImage
              image={getImage(
                imgDataArr[index].childImageSharp.gatsbyImageData
              )}
              alt={attraction.name}
              style={{ width: '100%' }}
            />
            <Card.Body style={{ textAlign: 'center' }}>
              <Card.Title>{attraction.name}</Card.Title>
            </Card.Body>
            <Card.Body style={{ textAlign: 'center' }}>
              {/* <Card.Link
                style={{ textDecoration: 'underline' }}
                href={attraction.mapLink}
              >
                Google Maps
              </Card.Link> */}
            </Card.Body>
          </a>
          </Card>
        );
      })}
        </ColInSection>
      </Section>
    </>
  );
};

export default SelfCateringUnits;
