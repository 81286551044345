import React from 'react';
import HomePageSlideShow from '../components/blocks/homePageSlideShow';
import QuoteBlock from '../components/blocks/QuoteBlock';
import { useTheme } from 'styled-components';
import { theme } from '../styles/theme';
import ShortTermUnits from '../components/blocks/shortTermUnits';
import SelfCateringUnits from '../components/blocks/selfCateringUnits';
import Attractions from '../components/blocks/attractions/attractions';
import ContactUs from '../components/blocks/contactUs';
// import { SEO, useSEO } from "gatsby-plugin-seo";


const HomePage = () => {
  const {greenRYB, babyPowder, yellowGreenCrayola} = useTheme(theme)
  // const { siteUrl } = useSEO();
  return (
    <>
      {/* <SEO
        title="Touch of Class Guest House"
        description="Classy, comfortable and stylish Guest House in Baileys Muckleneuk with a tranquil, beautiful garden"
      /> */}
      <HomePageSlideShow />
      <QuoteBlock 
        quote={`"Welcome to a tranquil, beautiful space"`} 
        backgroundColor={() => yellowGreenCrayola()}
        textColor={() => babyPowder()}
        textShadow={`0px 0px 10px ${greenRYB()}`}
      />
      <ShortTermUnits/>
      <SelfCateringUnits/>
      <Attractions />
      <ContactUs/>
    </>
  );
};

export default HomePage;
