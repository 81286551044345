import React from 'react';
import { ColInSection, Section } from '../layoutComponents';
import { useTheme } from 'styled-components/macro';
import { theme } from '../../styles/theme';
import Button from '../../styles/button';
import { StaticImage } from 'gatsby-plugin-image';
import {BsArrowRightShort} from 'react-icons/bs';
import {RiAlarmWarningLine, RiParkingBoxLine} from 'react-icons/ri';
import {FaSatelliteDish} from 'react-icons/fa';
import {MdKitchen} from 'react-icons/md';
import {IoWifi} from 'react-icons/io5';
import { Link } from 'gatsby';

const SelfCateringUnits = props => {
  const { wildBlueYonder, platinum, logoYellow } = useTheme(theme);

  return (
    //JSX
    <Section alignItems='flex-start' margin='50px auto auto' backgroundColor={logoYellow()} slanted={2} up>
      <ColInSection col={1}>
        <h2 style={{ textAlign: 'center' }}>Self Catering Suites</h2>
      </ColInSection>
      <ColInSection col={2}>
        <h4 style={{textAlign: 'center'}}>These suites are fully self catered and are perfect for long business trips or holidays.</h4>
        <ul style={{lineHeight: '2em', marginTop: 30}}>
          <li><RiAlarmWarningLine size='1.5em' style={{verticalAlign: 'text-top', marginRight: 20}}/><span style={{verticalAlign: 'sub'}}>{' '}Alarm system</span></li>
          <li><RiParkingBoxLine size='1.5em' style={{verticalAlign: 'text-top', marginRight: 20}}/><span style={{verticalAlign: 'sub'}}>{' '}Secure parking</span></li>
          <li><FaSatelliteDish size='1.5em' style={{verticalAlign: 'text-top', marginRight: 20}}/><span style={{verticalAlign: 'sub'}}>{' '}DSTV (satellite TV)</span></li>
          <li><MdKitchen size='1.5em' style={{verticalAlign: 'text-top', marginRight: 20}}/><span style={{verticalAlign: 'sub'}}>{' '}Full kitchen / Kitchenette</span></li>
          <li><IoWifi size='1.5em' style={{verticalAlign: 'text-top', marginRight: 20}}/><span style={{verticalAlign: 'sub'}}>{' '}Wifi in Unit</span></li>
          {/* <li>Secure parking</li>
          <li>DSTV (satellite TV)</li>
          <li>Full kitchen</li> */}
        </ul>
        <Button as={Link} to='/self-catering' bgColor={platinum()} style={{marginTop: 50, textAlign: 'center'}}>View All Units <BsArrowRightShort/></Button>
      </ColInSection>
      <ColInSection col={2}>
        <StaticImage 
          src='../../images/self_catering_units.jpg'
          alt='Self Catering Units'
          title='Self Catering Units'
          style={{width: '100%', borderRadius: 4}}
        />
      </ColInSection>
    </Section>
  );
}

export default SelfCateringUnits;