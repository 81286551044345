import React from 'react';
import { ColInSection, Section } from '../layoutComponents';
import { useTheme } from 'styled-components/macro';
import { theme } from '../../styles/theme';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '../../styles/button';
import { BsArrowRightShort } from 'react-icons/bs';
import { FaSatelliteDish } from 'react-icons/fa';
import { BiCoffee } from 'react-icons/bi';
import { RiPlantLine } from 'react-icons/ri';
import { GrWheelchair } from 'react-icons/gr';
import { IoWifi } from 'react-icons/io5';
import { Link } from 'gatsby';

const ShortTermUnits = (props) => {
  const { babyPowder, yellowGreenCrayola } = useTheme(theme);

  return (
    //JSX
    <Section id='accommodation' alignItems='flex-start' backgroundColor={babyPowder()}>
      <ColInSection col={1}>
        <h2 style={{ textAlign: 'center' }}>Guesthouse Rooms</h2>
      </ColInSection>
      <ColInSection col={2}>
        <StaticImage
          src='../../images/guesthouse_rooms.jpg'
          alt='Guesthouse Rooms'
          title='Guesthouse Rooms'
          style={{ width: '100%', borderRadius: 4 }}
        />
      </ColInSection>
      <ColInSection col={2}>
        <h4 style={{ textAlign: 'center' }}>
          These short term units are ideal for weekend retreats.
        </h4>
        <ul style={{ lineHeight: '2em', marginTop: 30 }}>
          <li>
            <RiPlantLine
              size='1.5em'
              style={{ verticalAlign: 'text-top', marginRight: 20 }}
            />
            <span style={{ verticalAlign: 'sub' }}>
              A stunning view of the sculptured green garden
            </span>
          </li>
          <li>
            <BiCoffee
              size='1.5em'
              style={{ verticalAlign: 'text-top', marginRight: 20 }}
            />
            <span style={{ verticalAlign: 'sub' }}>
              Tea and coffee facilities
            </span>
          </li>
          <li>
            <FaSatelliteDish
              size='1.5em'
              style={{ verticalAlign: 'text-top', marginRight: 20 }}
            />
            <span style={{ verticalAlign: 'sub' }}>DSTV (satellite TV)</span>
          </li>
          <li>
            <GrWheelchair
              size='1.5em'
              style={{ verticalAlign: 'text-top', marginRight: 20 }}
            />
            <span style={{ verticalAlign: 'sub' }}>
              Wheel chair friendly rooms
            </span>
          </li>
          <li>
            <IoWifi
              size='1.5em'
              style={{ verticalAlign: 'text-top', marginRight: 20 }}
            />
            <span style={{ verticalAlign: 'sub' }}> Wifi in Unit</span>
          </li>
        </ul>
        <Button
          as={Link}
          to='/rooms'
          bgColor={yellowGreenCrayola()}
          style={{ marginTop: 50, textAlign: 'center' }}
        >
          View All Rooms <BsArrowRightShort />
        </Button>
      </ColInSection>
    </Section>
  );
};

export default ShortTermUnits;
